import { CarbonInput, LandUse, Tillage } from '@/api/rest/resources/types/fact';

import { ScenarioType } from './hooks/types';

export const DEFAULT_TIME_FRAME = '10';

export const DEFAULT_SCENARIO_INPUTS = {
  scenarioType: ScenarioType.optimised,
  land_use: LandUse.cultivated,
  carbon_input: CarbonInput.high_c_input_with_manure,
  tillage: Tillage.no_till,
};
