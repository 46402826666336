import { ButtonLink, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { kgToTonneFormatter } from '@/components/Charts/utils';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCard/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsule/NCFactCapsule';
import { BenchmarkPill } from '@/components/Pill/BenchmarkPill';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { usePathSet } from '@/hooks/usePathSet';
import { useBenchmarkForFact, useBenchmarksForPlot } from '@/pages/shared/hooks/useBenchmarkForFact';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';
import { formatFactKilogramsToTonnes } from '@/utils/formatting';

export const CarbonStatsTile = () => {
  const { t } = useTranslation();
  const pathSet = usePathSet();
  const pathname = useLocation();
  const projectId = useProjectId();
  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });

  const carbonBgLatestAnalysis = formatFactKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );
  const cbgBenchmarks = useBenchmarksForPlot(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    kgToTonneFormatter,
  );
  const cbgBenchmarkType = useBenchmarkForFact(cbgBenchmarks, carbonBgLatestAnalysis?.value);

  const carbonBg1yChange = formatFactKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const carbonStorageBgTrend = getFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change
      : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change,
  );
  let carbonStorageBgTrendDisplay = `${useDisplayNumber(carbonStorageBgTrend?.value ?? 0, {
    signDisplay: 'always',
  })} %`;
  carbonStorageBgTrendDisplay = carbonStorageBgTrend?.value != null ? carbonStorageBgTrendDisplay : '-';

  const lastMeasuredDate = printLatestDate([carbonBgLatestAnalysis?.measured_at]);
  const carbonBg1yChangeInitialMeasuredAt = lastMeasuredDate;
  const carbonBg1yChangePreviousMeasuredAt = printLatestDate([carbonBg1yChange?.measured_at]);

  return (
    <Stack className='justify-center gap-8 p-6 lg:gap-4 lg:p-8 lg:pr-2' data-testid='carbon-stats-tile'>
      <div className='flex flex-col gap-8 lg:gap-4'>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.details.carbon.labels.belowTheGroundCarbonStorage')}
          supplementaryInfo={`${t('shared.ncaDetail.details.labels.updated')} ${lastMeasuredDate}`}
          popover={{
            title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
            body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
          }}
        />
        <Stack direction='row' className='flex-wrap gap-16'>
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.latestAnalysis')}
            fact={carbonBgLatestAnalysis}
            trend={
              typeof carbonStorageBgTrend?.value === 'number' && (
                <CapsuleTrend variant={carbonStorageBgTrend.value < 0 ? 'negative' : 'positive'}>
                  {carbonStorageBgTrendDisplay}
                </CapsuleTrend>
              )
            }
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonBgLatestAnalysis),
              variant: getNCChangeIconVariant(carbonBgLatestAnalysis),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
              body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', { date: lastMeasuredDate }),
              popoverTriggerProps: {
                className: 'text-xs',
              },
            }}
          />
          {!!carbonBg1yChange?.value && (
            <NCFactCapsule
              label={t('shared.ncaDetail.details.labels.oneYearChange')}
              fact={carbonBg1yChange}
              capsuleIconProps={{
                icon: getNCChangeIcon(carbonBg1yChange),
                variant: getNCChangeIconVariant(carbonBg1yChange),
              }}
              infoPopoverProps={{
                // TODO: MVP-3284 delete shared.ncaDetail.details.carbon.explainers.sincePreviousAnalysis if unused
                title: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.title'),
                body: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.body', {
                  date: carbonBg1yChangeInitialMeasuredAt,
                  previousDate: carbonBg1yChangePreviousMeasuredAt,
                }),
                popoverTriggerProps: {
                  'data-testid': 'cbg-since-previous-analysis-tooltip',
                },
              }}
            />
          )}
        </Stack>
        {cbgBenchmarkType && (
          <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
            <BenchmarkPill benchmarkType={cbgBenchmarkType} />
            <span className='typography-h4'>{t('shared.ncaDetail.details.carbon.explainers.cbgBenchmark.title')}</span>
            <Trans
              i18nKey={`shared.ncaDetail.details.carbon.explainers.cbgBenchmark.${cbgBenchmarkType}`}
              components={{
                p: <p className='typography-body1 text-text-secondary' />,
              }}
            />
            <ButtonLink
              to={buildPath(pathSet.impactProjections, { pathParams: { projectId } })}
              state={{
                previousPath: pathname,
              }}
              variant='outline'
              className='w-fit'
            >
              {t('shared.ncaDetail.details.carbon.explainers.cbgBenchmark.buttonLabel')}
            </ButtonLink>
          </Stack>
        )}
      </div>
    </Stack>
  );
};
