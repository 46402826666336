import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { WaterHoldingCapacityChart } from '@/components/Charts/WaterHoldingCapacity';
import { useNCCardContext } from '@/components/NCCard/NCCard';
import { useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { useNCData } from '@/pages/shared/hooks/useNCData';

import { NoProjectData } from '../../../components/NoProjectData';

export const WaterGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const waterHoldingCapacity10yearUpliftPotential = useNCData<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_graph
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha_graph,
  );
  const whcData = waterHoldingCapacity10yearUpliftPotential?.value;

  const wiltingPoint10yearUpliftPotential = useNCData<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_below_wilting_point_10year_uplift_potential_graph
      : R1FactType.r1_below_wilting_point_10year_uplift_potential_per_ha_graph,
  );
  const wpData = wiltingPoint10yearUpliftPotential?.value ?? [];

  const whcLowBenchmark = useNCData<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    undefined,
    [{ name: 'benchmark', value: 'low' }] as PlotReportFactLabel[],
  );
  const whcLowBenchmarkData = (whcLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const whcModerateBenchmark = useNCData<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    undefined,
    [{ name: 'benchmark', value: 'moderate' }] as PlotReportFactLabel[],
  );
  const whcModerateBenchmarkData = (whcModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const whcHighBenchmark = useNCData<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    undefined,
    [{ name: 'benchmark', value: 'high' }] as PlotReportFactLabel[],
  );
  const whcHighBenchmarkData = (whcHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const whcBenchmarks = useBenchmarksForProject(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
  );

  /**
   * We only null check for whcData since whcData is the primary source data of the graph
   * wpData and other data are reference lines that are displayed only when whcData is rendered
   */
  const noChartData = !whcData?.length;
  if (noChartData) {
    return <NoProjectData />;
  }

  const chartData = [
    ...whcData,
    ...wpData,
    ...whcLowBenchmarkData,
    ...whcModerateBenchmarkData,
    ...whcHighBenchmarkData,
  ];

  return (
    <Stack center data-testid='water-uplift-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'>
        <ChartHeading
          // TODO: MVP-3284 rename the key
          heading={t('shared.ncaDetail.details.water.labels.upliftGraphHeadline_v1_2')}
          popover={{
            title: t('shared.ncaDetail.details.water.explainers.upliftPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.upliftPotential.body'),
          }}
        />
        <WaterHoldingCapacityChart
          analysisType={analysisType}
          data={chartData}
          benchmarks={whcBenchmarks}
          showTooltip={true}
          className='relative'
          data-testid='water-holding-capacity-chart'
        />
      </Stack>
    </Stack>
  );
};
