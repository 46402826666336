import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { getXAxisDomainForHistoricGraph, minMaxAxisDomain } from '@/components/Charts/utils';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatUnit } from '@/utils/formatting';

import { NoProjectData } from '../../../components/NoProjectData';
import { Tile, TileCapsuleContent, TileCapsuleTitle, TileCta, TileHelper } from './Tile';

export const WaterTile = () => {
  const { t } = useTranslation();
  const pathSet =
    useMembershipType() === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward : paths.buyer;

  const project = useProject().data;
  const historicWhcPerHaGraph = useNCData<HistoricWaterAndSoilMoistureGraphData[]>(
    R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
  )
    ?.value?.filter((d) => d.name === 'water_holding_capacity_per_ha')
    // @ts-ignore typescript does not allow arithmetic operations between date objects
    .sort((pointA, pointB) => new Date(pointA.date) - new Date(pointB.date))
    .map((d) => ({ ...d, date: new Date(d.date).getTime() }));

  const whcLatestAnalysis = useNCData<number>(R1FactType.r1_water_holding_capacity_latest_analysis_per_ha);

  const chartData = historicWhcPerHaGraph;

  let whcLatestAnalysisDisplay = `${useDisplayNumber(whcLatestAnalysis?.value ?? 0)} ${formatUnit(UnitEnum['m^3/ha'])}`;
  whcLatestAnalysisDisplay = whcLatestAnalysis?.value != null ? whcLatestAnalysisDisplay : '-';

  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoProjectData />;
  }

  return (
    <Tile
      chart={<Chart chartData={chartData} />}
      capsuleTitle={<TileCapsuleTitle>{t('global.analysis.waterHoldingCapacity')}</TileCapsuleTitle>}
      capsuleContent={<TileCapsuleContent>{whcLatestAnalysisDisplay}</TileCapsuleContent>}
      cta={
        <TileCta to={buildPath(pathSet.projectDetailsWater, { pathParams: { projectId: project.id } })}>
          {t('shared.projects.project.landMonitoring.water.labels.seeAllWaterIndicators')}
        </TileCta>
      }
      helper={<TileHelper>{t('shared.projects.project.landMonitoring.water.helper')}</TileHelper>}
    />
  );
};

type HistoricWaterAndSoilMoistureGraphData = {
  date: number;
  name: 'water_holding_capacity_per_ha';
  value: number;
  unit: string;
};
const Chart = ({ chartData }: { chartData: HistoricWaterAndSoilMoistureGraphData[] }) => {
  const project = useProject().data;
  const isOutdatedValue = project.status !== ProjectStatus.analysed;

  const firstTick = chartData.at(0)?.date;
  const lastTick = chartData.at(-1)?.date;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick!, lastTick!) ?? [
    new Date().getFullYear(),
    new Date().getFullYear(),
  ];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const startYear = xTicksDomain[0]!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const endYear = xTicksDomain[1]!;
  const ticksRange = Array(endYear - startYear + 1).keys();
  const xTicks = Array.from(ticksRange).map((x) => new Date(`12-31-${startYear + x}`).getTime());

  return (
    <ResponsiveContainer data-testid='water-historic-chart-tile' width='100%' height={80}>
      <AreaChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id='waterHoldingCapacity' x1='0' y1='-0.85' x2='0' y2='1'>
            <stop offset='0%' stopColor={isOutdatedValue ? '#B4B4B4' : '#6ACDE1'} stopOpacity={1} />
            <stop offset='100%' stopColor={isOutdatedValue ? '#B4B4B4' : '#6ACDE1'} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={false}
          type='stepBefore'
          dataKey='value'
          stroke={isOutdatedValue ? '#595959' : '#71A7B2'}
          strokeWidth={2}
          fill='url(#waterHoldingCapacity)'
          fillOpacity={1}
        />
        <XAxis
          hide
          dataKey='date'
          scale='time'
          type='number'
          domain={['dataMin', 'dataMax']}
          ticks={xTicks}
          padding={{ left: 8, right: 8 }}
        />
        <YAxis
          hide
          dataKey='value'
          type='number'
          scale='sequential'
          width={0}
          domain={minMaxAxisDomain(0, 1.2)}
          padding={{ top: 8, bottom: 8 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
