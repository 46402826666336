import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { useBenchmarkForFact } from '@/pages/shared/hooks/useBenchmarkForFact';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { BenchmarkPill } from '../Pill/BenchmarkPill';
import { Area, Chart, ComposedChart, getXAxisProps, TooltipProps } from './components/Chart';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { RenderConfidenceInterval } from './ConfidenceInterval';
import { useLegendItems } from './hooks/useLegendItems';
import { NoChartData } from './NoChartData';
import { ChartDataItem, extrapolateReferenceLinesChartData, RenderReferenceLine } from './ReferenceLines';
import { ChartProps, FactValue } from './types';
import { getChartDataFromGraphFact, getXAxisDomainForPotentialGraph, minMaxAxisDomain, sortChartData } from './utils';

const chartColors = {
  whc: { stroke: '#3EC1DC', fill: '#85DAFF' },
  whc_historic: { stroke: '#84BAC4', fill: '#4AB2C7' },
} as const;

const totalFactNames = {
  water_holding_capacity: 'whc_historic',
  water_holding_capacity_potential: 'whc',
  water_fc_total_benchmark_low: 'whc_benchmark_low',
  water_fc_total_benchmark_moderate: 'whc_benchmark_moderate',
  water_fc_total_benchmark_high: 'whc_benchmark_high',
};

const perHaFactNames = {
  water_holding_capacity_per_ha: 'whc_historic',
  water_holding_capacity_potential_per_ha: 'whc',
  water_fc_per_ha_benchmark_low: 'whc_benchmark_low',
  water_fc_per_ha_benchmark_moderate: 'whc_benchmark_moderate',
  water_fc_per_ha_benchmark_high: 'whc_benchmark_high',
};

const intrapolateNullValues = (data: ChartDataItem[]) => {
  const interpolatedData = structuredClone(data);
  const historicWhcYearlyValues: Record<string, FactValue | null> = {};

  Object.entries(interpolatedData).forEach(([, currentData]) => {
    historicWhcYearlyValues[new Date(currentData.label).getFullYear().toString()] = currentData.whc_historic ?? null;
  });
  Object.entries(interpolatedData).forEach(([date, currentData]) => {
    /**
     * Science spec -
     * whc is produced as 1 value per year, constant throughout the year, sm can have multiple values in a year
     * we use this whc value to fill in whc for all sm datapoints of that year that do not have a coninciding whc datapoint
     * so that the tooltip does not show "No data" for whc since whc is null for those dates
     * but as shown in the graph the step chart for whc would indicate a corresponding whc value
     */
    const defaultHistoricWhcValue =
      currentData.whc_historic ?? historicWhcYearlyValues[new Date(currentData.label).getFullYear().toString()];

    interpolatedData[Number(date)] = {
      ...interpolatedData[Number(date)],
      whc_historic: defaultHistoricWhcValue ?? null,
    };
  });
  return interpolatedData;
};

export const WaterHoldingScenarioChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  analysisType,
  data,
  benchmarks,
  className,
  showTooltip,
  ...delegated
}) => {
  const { t } = useTranslation();

  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);

  const factNames = analysisType === 'total' ? totalFactNames : perHaFactNames;
  const dateDataMap = getChartDataFromGraphFact(data, factNames);
  const sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());
  const interpolatedData = intrapolateNullValues(sortedChartData);

  const firstTick = interpolatedData.at(0);
  const lastTick = interpolatedData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  const xTicksDomain = getXAxisDomainForPotentialGraph(firstTick.label, lastTick.label);
  const xAxisProps = getXAxisProps(xTicksDomain);

  let chartData = extrapolateReferenceLinesChartData(xTicksDomain, interpolatedData, 'whc_benchmark_low');
  chartData = extrapolateReferenceLinesChartData(xTicksDomain, chartData, 'whc_benchmark_moderate');
  chartData = extrapolateReferenceLinesChartData(xTicksDomain, chartData, 'whc_benchmark_high');

  return (
    <>
      <Chart.Container
        {...delegated}
        data={chartData}
        config={{
          whc: {
            label: t('shared.projects.project.impactProjections.labels.optimizedScenario'),
            color: chartColors.whc.stroke,
          },
          whc_historic: {
            label: t('shared.projects.project.impactProjections.labels.historicWaterHoldingCapacity'),
            color: chartColors.whc_historic.stroke,
          },
        }}
      >
        <ComposedChart data={chartData}>
          <defs>
            <linearGradient id='whc' x1='0' y1='-0.86' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.whc.fill} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.whc.fill} stopOpacity={0} />
            </linearGradient>
            <linearGradient id='whc_historic' x1='0' y1='-0.86' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.whc_historic.fill} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.whc_historic.fill} stopOpacity={0} />
            </linearGradient>
          </defs>
          {RenderConfidenceInterval({
            dataKey: 'whc_historic.confidence_interval',
            type: 'monotone',
          })}
          <Area
            type='stepBefore'
            isAnimationActive={false}
            stroke='var(--color-whc_historic)'
            strokeWidth={2}
            fill='url(#whc_historic)'
            dataKey='whc_historic.value'
            connectNulls
            activeDot={ChartTooltipDot}
          />
          {RenderConfidenceInterval({
            dataKey: 'whc.confidence_interval',
            type: 'monotone',
          })}
          <Area
            type='monotone'
            isAnimationActive={false}
            stroke='var(--color-whc)'
            strokeWidth={2}
            strokeDasharray='4 4'
            fill='url(#whc)'
            fillOpacity={1}
            dataKey='whc.value'
            connectNulls
            activeDot={ChartTooltipDot}
          />
          {RenderReferenceLine({
            dataKey: 'whc_benchmark_low.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.whc_benchmark_low) ? t('global.benchmark.low') : null,
            infoPopoverProps: {
              body: t('shared.ncaDetail.details.water.explainers.whcBenchmark.tooltips.low'),
            },
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'whc_benchmark_moderate.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.whc_benchmark_moderate) ? t('global.benchmark.moderate') : null,
            infoPopoverProps: {
              body: t('shared.ncaDetail.details.water.explainers.whcBenchmark.tooltips.moderate'),
            },
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'whc_benchmark_high.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.whc_benchmark_high) ? t('global.benchmark.high') : null,
            infoPopoverProps: {
              body: t('shared.ncaDetail.details.water.explainers.whcBenchmark.tooltips.high'),
            },
            chartData,
          })}
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={minMaxAxisDomain(0.97, 1.2)}>
            <Chart.Label>{unit}</Chart.Label>
          </Chart.YAxis>
          {showTooltip && (
            <Chart.Tooltip
              content={(props) => <CustomTooltip {...props} unit={unit as UnitEnum} benchmarks={benchmarks} />}
            />
          )}
        </ComposedChart>
      </Chart.Container>
      <CustomLegendBar />
    </>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();
  const defaultLegendItems = useLegendItems();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('shared.projects.project.impactProjections.labels.historicWaterHoldingCapacity'),
      color: chartColors.whc_historic.fill,
    },
    {
      label: t('shared.projects.project.impactProjections.labels.optimizedScenario'),
      color: chartColors.whc.fill,
      shape: (
        <span className='w-[14px] border-y-[3.5px] border-dotted' style={{ borderColor: chartColors.whc.stroke }} />
      ),
    },
    ...defaultLegendItems,
  ];

  return <ChartLegendBar items={legendBarItems} data-testid='water-holding-capacity-chart-legend' />;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  unit,
  benchmarks,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TooltipProps<ValueType, NameType> & { unit: UnitEnum; benchmarks?: any[] }) => {
  const { t } = useTranslation();

  const whcScenarioValue = payload?.find((data) => data.dataKey === 'whc.value');
  const whcHistoricValue = payload?.find((data) => data.dataKey === 'whc_historic.value');

  const whcValue = whcScenarioValue ?? whcHistoricValue;

  const whcDisplay =
    whcValue?.value != null
      ? `${getDisplayNumber(whcValue?.value as string, window.navigator.language)} ${unit}`
      : t('global.analysis.noData');

  const whcBenchmarkType = useBenchmarkForFact(benchmarks, whcValue?.value);

  if (!active) return null;

  return (
    <ChartTooltip>
      {whcBenchmarkType && (
        <ChartTooltipTextPrimary className='mt-1'>
          <BenchmarkPill benchmarkType={whcBenchmarkType} />
        </ChartTooltipTextPrimary>
      )}
      <ChartTooltipTextPrimary>{`${t('global.analysis.waterHoldingCapacity')}: ${whcDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printMonthYear(label)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
