import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { NCCard, NCCard2SectionLayout, NCCardAnalysisTypeToggle, NCCardHeader } from '@/components/NCCard/NCCard';
import { CarbonUpliftPotentialExplainer } from '@/components/NCExplainers/CarbonUpliftPotentialExplainer';
import { WhcUpliftPotentialExplainer } from '@/components/NCExplainers/WhcUpliftPotentialExplainer';
import { useScreenSize } from '@/hooks/useScreenSize';

import { CarbonGraphTile } from './components/CarbonGraphTile';
import { CarbonStatsTile } from './components/CarbonStatsTile';
import { WaterGraphTile } from './components/WaterGraphTile';
import { WaterStatsTile } from './components/WaterStatsTile';

export const ImpactProjectionsPage = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <Stack spacing={10} data-testid='impact-projections'>
      <NCCard explainer={<CarbonUpliftPotentialExplainer />} data-testid='carbon-impact-projections'>
        <NCCardHeader title={t('global.analysis.carbon')}>
          {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
        </NCCardHeader>
        <NCCard2SectionLayout className='md:min-h-[400px]'>
          <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
            {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
            <CarbonGraphTile />
          </Stack>
          <CarbonStatsTile />
        </NCCard2SectionLayout>
      </NCCard>

      <NCCard explainer={<WhcUpliftPotentialExplainer />} data-testid='water-impact-projections'>
        <NCCardHeader title={t('global.analysis.water')}>
          {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
        </NCCardHeader>
        <NCCard2SectionLayout className='md:min-h-[400px]'>
          <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
            {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
            <WaterGraphTile />
          </Stack>
          <WaterStatsTile />
        </NCCard2SectionLayout>
      </NCCard>
    </Stack>
  );
};
