import { Trans, useTranslation } from 'react-i18next';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from '../../../../../../../../components/NCExplainers/Explainer';

export const ImpactProjectionsExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='impact-projections-explainer'>
        {t('shared.ncaDetail.details.labels.howDoWeMeasureThis')}
      </ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>
          {t('shared.projects.project.impactProjections.explainers.howDoMeasureThis.headline')}
        </ExplainerTitle>
        <ExplainerBody>
          <ExplainerCopy
            title={t('shared.projects.project.impactProjections.explainers.howDoMeasureThis.impactProjections.title')}
            body={t('shared.projects.project.impactProjections.explainers.howDoMeasureThis.impactProjections.body')}
          />
          <ExplainerCopy
            title={t('shared.projects.project.impactProjections.explainers.howDoMeasureThis.improvementMethods.title')}
            body={
              <Trans
                i18nKey='shared.projects.project.impactProjections.explainers.howDoMeasureThis.improvementMethods.body'
                components={{
                  ol: <ol className='ml-6 list-outside list-disc pt-4' />,
                  li: <li />,
                }}
              />
            }
          />
        </ExplainerBody>
      </ExplainerContent>
    </Explainer>
  );
};
