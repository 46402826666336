/* eslint-disable security/detect-object-injection */
import { useParams } from 'react-router-dom';

import { BenchmarkType, PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { GraphFact } from '@/components/Charts/types';

import { useNCData } from './useNCData';
import { usePlotReportForPlot } from './usePlotReportForPlot';

export const useBenchmarksForProject = (
  benchmarkType: R1FactType,
  projectIdArg?: string | null,
  formatter?: (fact: GraphFact | undefined) => number | null | undefined,
) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  if (!projectId) {
    throw new Error('Missing URL param: projectId');
  }

  const lowBenchmarkData = useNCData<GraphFact[]>(benchmarkType, projectId, [
    { name: 'benchmark', value: 'low' },
  ] as PlotReportFactLabel[])?.value;

  const moderateBenchmarkData = useNCData<GraphFact[]>(benchmarkType, projectId, [
    { name: 'benchmark', value: 'moderate' },
  ] as PlotReportFactLabel[])?.value;

  const highBenchmarkData = useNCData<GraphFact[]>(benchmarkType, projectId, [
    { name: 'benchmark', value: 'high' },
  ] as PlotReportFactLabel[])?.value;

  const lowBenchmark = formatter?.(lowBenchmarkData?.at(-1)) ?? lowBenchmarkData?.at(-1)?.value;
  const moderateBenchmark = formatter?.(moderateBenchmarkData?.at(-1)) ?? moderateBenchmarkData?.at(-1)?.value;
  const highBenchmark = formatter?.(highBenchmarkData?.at(-1)) ?? highBenchmarkData?.at(-1)?.value;

  return [lowBenchmark, moderateBenchmark, highBenchmark];
};

export const useBenchmarksForPlot = (
  benchmarkType: R1FactType,
  plotIdArg?: string | null,
  formatter?: (fact: GraphFact | undefined) => number | null | undefined,
) => {
  const routeParams = useParams();
  const plotId = plotIdArg ?? routeParams.plotId;

  const { getFact } = usePlotReportForPlot({ plotId: plotId ?? null });

  const lowBenchmarkData = getFact<GraphFact[]>(benchmarkType, [
    { name: 'benchmark', value: 'low' },
  ] as PlotReportFactLabel[])?.value;

  const moderateBenchmarkData = getFact<GraphFact[]>(benchmarkType, [
    { name: 'benchmark', value: 'moderate' },
  ] as PlotReportFactLabel[])?.value;

  const highBenchmarkData = getFact<GraphFact[]>(benchmarkType, [
    { name: 'benchmark', value: 'high' },
  ] as PlotReportFactLabel[])?.value;

  const lowBenchmark = formatter?.(lowBenchmarkData?.at(-1)) ?? lowBenchmarkData?.at(-1)?.value;
  const moderateBenchmark = formatter?.(moderateBenchmarkData?.at(-1)) ?? moderateBenchmarkData?.at(-1)?.value;
  const highBenchmark = formatter?.(highBenchmarkData?.at(-1)) ?? highBenchmarkData?.at(-1)?.value;

  return [lowBenchmark, moderateBenchmark, highBenchmark];
};

export const useBenchmarkForFact = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  benchmarks: any[] | null | undefined,
  fact: string | number | null | undefined | unknown,
) => {
  if (!fact || !benchmarks || benchmarks.some((bm) => bm == null)) {
    return null;
  }

  const factValue = Number(fact);

  const getClosestBenchmark = (bms: number[], value: number) => {
    const differences = bms.map((benchmark) => Math.abs(value - benchmark));
    const minIndex = differences.indexOf(Math.min(...differences));
    return bms[minIndex];
  };

  const benchmark = getClosestBenchmark(benchmarks as number[], factValue);
  switch (benchmark) {
    case benchmarks?.[0]:
      return BenchmarkType.low;
    case benchmarks?.[1]:
      return BenchmarkType.moderate;
    case benchmarks?.[2]:
      return BenchmarkType.high;
    default:
      return null;
  }
};
