import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  FactsByFactType,
  GET_BUYER_DASHBOARD_PLOT_REPORT_PATH,
  GET_DASHBOARD_PLOT_REPORT_PATH,
  GetBuyerDashboardPlotReportErrorDto,
  GetBuyerDashboardPlotReportSuccessDto,
  GetDashboardPlotReportErrorDto,
  GetDashboardPlotReportSuccessDto,
} from '@/api/rest/resources/types/dashboard';
import { PlotReportFactElement, PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';

type SuccessDto = GetDashboardPlotReportSuccessDto | GetBuyerDashboardPlotReportSuccessDto | null;
type ErrorDto = GetDashboardPlotReportErrorDto | GetBuyerDashboardPlotReportErrorDto;

export type ReportByPlot = Record<string, FactsByFactType>;

export const getPlotReportForPlot = async ({
  plotId,
  membershipType,
}: {
  plotId: string;
  membershipType: MembershipWithOrganizationTypeEnum;
}) => {
  return getRequest<SuccessDto>(
    membershipType === MembershipWithOrganizationTypeEnum.land_steward
      ? GET_DASHBOARD_PLOT_REPORT_PATH
      : GET_BUYER_DASHBOARD_PLOT_REPORT_PATH,
    { plot_id: plotId },
    undefined,
  );
};

const hasLabel = (filterLabel: PlotReportFactLabel, labels: PlotReportFactLabel[]) => {
  return labels.some((label) => {
    return Object.keys(filterLabel).every(
      (key) => label[key as keyof PlotReportFactLabel] === filterLabel[key as keyof PlotReportFactLabel],
    );
  });
};

const hasLabels = (filterLabels: PlotReportFactLabel[], labels: PlotReportFactLabel[]) => {
  return filterLabels.every((filterLabel) => hasLabel(filterLabel, labels));
};

export const usePlotReportForPlot = ({ plotId }: { plotId: string | null }) => {
  const membershipType = useMembershipType();

  const dashboardData = useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'dashboard', 'plot-report', plotId],
    queryFn: () => (plotId ? getPlotReportForPlot({ plotId, membershipType }) : null),
    staleTime: 1000 * 60 * 5,
  });

  return {
    ...dashboardData.data,

    getFact: <T = unknown>(factType: R1FactType, labels?: PlotReportFactLabel[]) => {
      return dashboardData.data?.facts.find(
        (f) => f.name === factType && (labels && f.labels ? hasLabels(labels, f.labels) : true),
      ) as PlotReportFactElement<T | null> | undefined;
    },
  };
};
