import * as SelectPrimitive from '@radix-ui/react-select';
import React, { ElementRef, forwardRef } from 'react';

import { RiArrowDownSFill, RiArrowUpSFill, RiCheckLine } from '../Icon/Icon';
import { cn } from '../utils';

export type SelectProps = SelectPrimitive.SelectProps & {
  onChange?: SelectPrimitive.SelectProps['onValueChange'];
  ref?: never;
};

export const Select = forwardRef<never, SelectProps>(
  (
    { onChange, onValueChange, ...delegated },
    /**
     * There is no DOM element rendered by this component, and there is no ref
     * being forwarded. It is just here for compatibility with react-hook-form.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    _,
  ) => {
    return <SelectPrimitive.Root onValueChange={onValueChange ?? onChange} {...delegated} />;
  },
);
Select.displayName = 'Select';

export type SelectTriggerProps = SelectPrimitive.SelectTriggerProps & {
  leftAdornment?: React.ReactNode;
  /**
   * Default: Down arrow icon.
   */
  rightAdornment?: React.ReactNode;
  placeholder?: string;
};

export const SelectTrigger = forwardRef<ElementRef<typeof SelectPrimitive.Trigger>, SelectTriggerProps>(
  ({ children, className, placeholder, leftAdornment, rightAdornment, asChild, ...delegated }, ref) => {
    return (
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          'group flex w-full items-center rounded',
          'border border-outlined-border-23p p-4 hover:border-text-primary',
          'data-[placeholder]:text-text-disabled',
          'disabled:cursor-not-allowed disabled:text-text-disabled disabled:hover:border-outlined-border-23p',
          'data-[invalid=true]:border-error',
          className,
        )}
        {...delegated}
      >
        {leftAdornment && <div className='mr-2'>{leftAdornment}</div>}
        {asChild ? children : <SelectPrimitive.Value placeholder={placeholder}>{children}</SelectPrimitive.Value>}
        <div className='flex-1' />
        <div className='text-text-primary group-disabled:text-text-disabled group-data-[invalid=true]:text-error'>
          {rightAdornment ?? (
            <SelectPrimitive.Icon>
              <RiArrowDownSFill />
            </SelectPrimitive.Icon>
          )}
        </div>
      </SelectPrimitive.Trigger>
    );
  },
);
SelectTrigger.displayName = 'SelectTrigger';

export type SelectContentProps = SelectPrimitive.SelectContentProps;

export const SelectContent = forwardRef<ElementRef<typeof SelectPrimitive.Content>, SelectContentProps>(
  ({ className, children, ...delegated }, ref) => {
    return (
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          ref={ref}
          className={cn('z-dialog overflow-hidden rounded bg-white-100 animate-in fade-in-0 elevation-1', className)}
          {...delegated}
        >
          <SelectPrimitive.ScrollUpButton className='flex cursor-default items-center justify-center border-b border-disabled-button-bg'>
            <RiArrowUpSFill />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton className='bg-white text-violet11 flex h-[25px] cursor-default items-center justify-center border-t border-disabled-button-bg'>
            <RiArrowDownSFill />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    );
  },
);
SelectContent.displayName = 'SelectContent';

export type SelectItemProps = SelectPrimitive.SelectItemProps & {
  leftAdornment?: React.ReactNode;
};

export const SelectItem = React.forwardRef<ElementRef<typeof SelectPrimitive.Item>, SelectItemProps>(
  ({ children, className, leftAdornment, asChild, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item
        ref={forwardedRef}
        className={cn(
          'typography-body2 relative flex cursor-default items-center py-2 pl-4 pr-10 text-left',
          'data-[highlighted]:bg-primary-12 data-[state=checked]:bg-neutral-black-8',
          'data-[disabled]:cursor-not-allowed data-[disabled]:text-text-disabled',
          className,
        )}
        {...props}
      >
        {leftAdornment && <div className='mr-2'>{leftAdornment}</div>}
        {asChild ? children : <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>}
        <SelectPrimitive.ItemIndicator className='absolute right-3 top-0 inline-flex h-full items-center justify-center'>
          <RiCheckLine />
        </SelectPrimitive.ItemIndicator>
      </SelectPrimitive.Item>
    );
  },
);
SelectItem.displayName = 'SelectItem';

export type SelectItemTextProps = SelectPrimitive.SelectItemTextProps;

export const SelectItemText = SelectPrimitive.ItemText;

export type SelectGroupProps = SelectPrimitive.SelectGroupProps;

export const SelectGroup = SelectPrimitive.Group;

export type SelectLabelProps = SelectPrimitive.SelectLabelProps;

export const SelectLabel = forwardRef<ElementRef<typeof SelectPrimitive.Label>, SelectLabelProps>(
  ({ className, ...delegated }, ref) => {
    return (
      <SelectPrimitive.Label
        ref={ref}
        className={cn('typography-overline mx-4 mb-3 mt-5 font-semibold uppercase text-text-disabled', className)}
        {...delegated}
      />
    );
  },
);
SelectLabel.displayName = 'SelectLabel';

export type SelectSeparatorProps = SelectPrimitive.SelectSeparatorProps;

export const SelectSeparator = forwardRef<ElementRef<typeof SelectPrimitive.Separator>, SelectSeparatorProps>(
  ({ className, ...delegated }, ref) => {
    return <SelectPrimitive.Separator ref={ref} className={cn('h-[1px] bg-divider', className)} {...delegated} />;
  },
);
SelectSeparator.displayName = 'SelectSeparator';
