import { CarbonInput, LandUse, Tillage } from '@/api/rest/resources/types/fact';

import { TIME_FRAMES } from './constants';

export enum ScenarioType {
  optimised = 'optimised',
  customised = 'customised',
}

export type OptimisationInput = {
  scenarioType: ScenarioType;
};

export type LabelsInput = {
  land_use: LandUse;
  carbon_input: CarbonInput;
  tillage: Tillage;
};

export type SettingsInput = {
  landPortionId: string;
  timeFrame: keyof typeof TIME_FRAMES;
};

export type ScenarioInputs = SettingsInput & LabelsInput & OptimisationInput;
