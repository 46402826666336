/* eslint-disable security/detect-object-injection */
import { InfoPopover, InfoPopoverProps, Stack } from '@landler/tw-component-library';
import { ReactNode, useRef } from 'react';
import { LabelList, LabelProps, Line } from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import { useResizeObserver } from 'usehooks-ts';

import { useScreenSize } from '@/hooks/useScreenSize';

import { SHARED_CHART_COLORS } from './constants';
import { FactDataPoint } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ChartDataItem = Record<keyof FactDataPoint, any>;

export const extrapolateReferenceLinesChartData = (
  domain: number[],
  data: ChartDataItem[],
  dataKey: keyof FactDataPoint,
) => {
  /**
   * Science/Design spec -
   * Reference lines should extend the whole range of the graph on both ends (min to max).
   * In cases where the timeseries data for reference lines might have gaps, or do not extend the whole min-max range -
   *    with connectNulls - recharts interpolates over null values and extends the line internally to fill in the gaps in these lines.
   *    For extrapolation, such that the line gets extended externally to the start and ends of the parent graph
   *    we manually fill in the min and max point for reference lines so that interpolation can take over.
   *    We pick the first non-null value as the min, and the last non-null value as the max.
   */
  const firstDataKeyItemValue =
    data.find((point) => point[dataKey] !== null && point[dataKey] !== undefined && point[dataKey].value !== null)?.[
      dataKey
    ] ?? null;
  const lastDataKeyItemValue =
    data.findLast(
      (point) => point[dataKey] !== null && point[dataKey] !== undefined && point[dataKey].value !== null,
    )?.[dataKey] ?? null;

  let firstDomainItemIndex = data.findIndex((point) => new Date(point.label).getFullYear() === domain[0]);
  let lastDomainItemIndex = data.findLastIndex((point) => new Date(point.label).getFullYear() === domain[1]);

  if (firstDomainItemIndex < 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    data.unshift({ label: new Date(domain[0]!, 11, 31).valueOf(), [dataKey]: firstDataKeyItemValue });
  }
  if (lastDomainItemIndex < 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    data.push({ label: new Date(domain[1]!, 11, 31).valueOf(), [dataKey]: lastDataKeyItemValue });
  }

  firstDomainItemIndex = firstDomainItemIndex < 0 ? 0 : firstDomainItemIndex;
  const firstItem = data[firstDomainItemIndex];

  lastDomainItemIndex = lastDomainItemIndex < 0 ? data.length - 1 : lastDomainItemIndex;
  const lastItem = data[lastDomainItemIndex];

  const extrapolatedData = structuredClone(data);

  if (!firstItem || !lastItem) {
    return extrapolatedData;
  }

  extrapolatedData[firstDomainItemIndex] = {
    ...firstItem,
    [dataKey]: firstItem[dataKey] ?? firstDataKeyItemValue,
  };
  extrapolatedData[lastDomainItemIndex] = {
    ...lastItem,
    [dataKey]: lastItem[dataKey] ?? lastDataKeyItemValue,
  };

  return extrapolatedData;
};

export type ReferenceLineProps = {
  dataKey: string;
  type: string;
  label?: string | null;
  infoPopoverProps?: InfoPopoverProps | null;
  chartData: ChartDataItem[];
};

export const RenderReferenceLine = ({ dataKey, type, label, infoPopoverProps, chartData }: ReferenceLineProps) => {
  return (
    <Line
      type={type as CurveType}
      dataKey={dataKey}
      connectNulls
      activeDot={false}
      isAnimationActive={false}
      stroke={SHARED_CHART_COLORS.referenceLines.stroke}
      strokeDasharray='4 4'
      dot={false}
      strokeWidth={1}
    >
      <LabelList
        dataKey=''
        content={(props) =>
          label &&
          props.index === chartData.length - 1 && (
            <ReferenceLineLabel label={label} infoPopoverProps={infoPopoverProps} {...props} />
          )
        }
      />
    </Line>
  );
};

export type ReferenceLineLabelProps = LabelProps & {
  label: ReactNode;
  infoPopoverProps?: InfoPopoverProps | null;
};

export const ReferenceLineLabel = (props: ReferenceLineLabelProps) => {
  const { x, y, label, infoPopoverProps } = props;

  const isSmallScreen = useScreenSize() === 'small';

  const labelRef = useRef(null);
  const { width, height } = useResizeObserver({ ref: labelRef });

  return (
    <foreignObject
      x={(x as number) - ((width ?? 0) + 16)}
      y={(y as number) - 32}
      width={width || '100%'}
      height={height || '100%'}
      style={{ overflow: 'visible' }}
    >
      <Stack
        ref={labelRef}
        direction='row'
        spacing={2}
        className='w-fit self-end rounded bg-[rgba(255,_255,_255,_0.6)] p-1'
      >
        <Stack direction='row' spacing={1} className='typography-caption whitespace-nowrap'>
          {label}
          {!isSmallScreen && infoPopoverProps && <InfoPopover {...infoPopoverProps} />}
        </Stack>
      </Stack>
    </foreignObject>
  );
};
