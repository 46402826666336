import { ButtonLink, RiArrowRightUpLine, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { useMethodologyPageLink } from '@/pages/shared/projects/pages/project/components/conservation/hooks/useMethodologyPageLink';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const NatureUnitBalanceExplainer = () => {
  const { t } = useTranslation();
  const methodologyPageLink = useMethodologyPageLink();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='nature-unit-balance-explainer'>{t('global.howDoWeMeasureThis')}</ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>
          {t('shared.ncaDetail.details.natureUnitBalance.explainers.howDoWeMeasureThis.title')}
        </ExplainerTitle>
        <Stack spacing={8} className='items-start pb-6'>
          <ExplainerBody className='pb-0'>
            <ExplainerCopy
              body={<Trans i18nKey='shared.ncaDetail.details.natureUnitBalance.explainers.howDoWeMeasure.body' />}
            />
          </ExplainerBody>
          <ExplainerBody className='py-0'>
            <ExplainerCopy
              title={<Trans i18nKey='shared.ncaDetail.details.natureUnitBalance.explainers.habitatIntactness.title' />}
              body={<Trans i18nKey='shared.ncaDetail.details.natureUnitBalance.explainers.habitatIntactness.body' />}
            />
            <ExplainerCopy
              title={<Trans i18nKey='shared.ncaDetail.details.natureUnitBalance.explainers.floraAndFauna.title' />}
              body={<Trans i18nKey='shared.ncaDetail.details.natureUnitBalance.explainers.floraAndFauna.body' />}
            />
            <ExplainerCopy
              title={
                <Trans
                  i18nKey='shared.ncaDetail.details.natureUnitBalance.explainers.connectivity.title'
                  components={{ small: <small className='typography-h4 text-text-secondary' /> }}
                />
              }
              body={<Trans i18nKey='shared.ncaDetail.details.natureUnitBalance.explainers.connectivity.body' />}
            />
          </ExplainerBody>
          <ButtonLink
            to={methodologyPageLink}
            target='_blank'
            variant='text'
            rightAdornment={<RiArrowRightUpLine />}
            className='-left-5'
          >
            {t('shared.learnMoreAboutMethodology')}
          </ButtonLink>
        </Stack>
      </ExplainerContent>
    </Explainer>
  );
};
