import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { NCCard, NCCard2SectionLayout, NCCardAnalysisTypeToggle, NCCardHeader } from '@/components/NCCard/NCCard';
import { useScreenSize } from '@/hooks/useScreenSize';

import { ImpactProjectionsExplainer } from '../ImpactProjectionsExplainer';
import { WaterGraphTile } from './WaterGraphTile';
import { WaterStatsTile } from './WaterStatsTile';

export const WaterCard = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <NCCard explainer={<ImpactProjectionsExplainer />} data-testid='water-impact-projections'>
      <NCCardHeader title={t('global.analysis.water')}>
        {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
      </NCCardHeader>
      <NCCard2SectionLayout className='md:min-h-[400px]'>
        <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
          {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
          <WaterGraphTile />
        </Stack>
        <WaterStatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};
