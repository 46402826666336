import { PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { assertIsDefined } from '@/utils/assert';

import { LabelsInput } from './types';
import { useIsLandPortionProject } from './useIsLandPortionProject';
import { useScenarioForm } from './useScenarioForm';

export const getFormLabels = (labels: LabelsInput) => {
  return Object.keys(labels)
    .filter((label) => ['carbon_input', 'tillage', 'land_use'].includes(label))
    .map(
      (label) =>
        ({
          name: label,
          value: labels[label as keyof LabelsInput],
        } as PlotReportFactLabel),
    );
};

export const useReportFact = <T = unknown>(type: R1FactType, labels?: PlotReportFactLabel[]) => {
  const scenarioForm = useScenarioForm();
  const { landPortionId, ...values } = scenarioForm.watch();

  const filterLabels = [...getFormLabels(values), ...(labels ?? [])];

  assertIsDefined(landPortionId, 'landPortionId');

  const isLandPortionProject = useIsLandPortionProject();

  const projectFact = useNCData<T>(type, isLandPortionProject ? landPortionId : undefined, filterLabels);

  const { getFact } = usePlotReportForPlot({ plotId: !isLandPortionProject ? landPortionId : null });
  const plotFact = getFact<T>(type, filterLabels);

  return isLandPortionProject ? projectFact : plotFact;
};
