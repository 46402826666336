import { useTranslation } from 'react-i18next';

import { NCAReportBalanceType, NCAReportBalanceValueType } from '@/api/rest/resources/types/ncaBalance';
import { NCCardSectionHeader } from '@/components/NCCard/NCCard';
import { useNCABalance, useNCAReportBalance } from '@/pages/shared/hooks/useNcaReportByProjectId';

import { NCABalanceTile } from './NCABalanceTile';

export const WaterBalance = () => {
  const { t } = useTranslation();

  const waterBalance = useNCAReportBalance(NCAReportBalanceType.WATER);

  const openingValue = useNCABalance(waterBalance, NCAReportBalanceValueType.opening_value);
  const latestValue = useNCABalance(waterBalance, NCAReportBalanceValueType.latest_value);
  const latestValueTrend = useNCABalance(waterBalance, NCAReportBalanceValueType.one_year_change_perc);
  const oneYearChange = useNCABalance(waterBalance, NCAReportBalanceValueType.one_year_change_value);

  return (
    <div data-testid='water-stats-tile' className='flex w-full flex-col gap-6'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.waterHoldingCapacityData')}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.body'),
        }}
      />
      <NCABalanceTile
        openingValue={openingValue}
        latestValue={latestValue}
        latestValueTrend={latestValueTrend}
        oneYearChange={oneYearChange}
      />
    </div>
  );
};
