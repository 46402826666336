import { RiCompass4Line, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCard/NCCard';
import { FloraAndFaunaExplainer } from '@/components/NCExplainers/FloraAndFaunaExplainer';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { printMonthYear } from '@/utils/formatting/date';
import { range } from '@/utils/range';

import { Area, AreaChart, Chart, useTimeseriesXAxisProps } from '../../../components/conservation/components/Chart';
import { FactCapsule } from '../../../components/conservation/components/FactCapsule';
import { useFact } from '../../../components/conservation/hooks/useFact';
import { useLatestAnalysisDatestamp } from '../../../components/conservation/hooks/useLatestAnalysisDatestamp';

export const FloraAndFaunaEvolutionCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<FloraAndFaunaExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.floraAndFauna.evolution.title')} />

      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const currentYear = useFact<number>('r1_conservation_current_year').value;
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value;
  const indicatorSpeciesTimeseries = useFact<Record<`${string}`, number>>('r1_conservation_indicator_species_ts').value;

  const chartData = range(baselineYear, currentYear).map((year) => ({
    floraAndFauna: indicatorSpeciesTimeseries[`${year}`] ?? null,
    date: new Date(`${year}`),
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.floraAndFauna.evolution.chartHeading')} />

      <Chart.Container
        data={chartData}
        config={{
          floraAndFauna: {
            label: t('shared.ncaDetail.floraAndFauna.evolution.attributeLabel.floraAndFauna'),
            color: '#4E2F7C',
          },
        }}
      >
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id='floraAndFaunaFill' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#A892C9' stopOpacity={0.53} />
              <stop offset='100%' stopColor='#DBD2E9' stopOpacity={0.19} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            strokeWidth={3}
            stroke='var(--color-floraAndFauna)'
            fill='url(#floraAndFaunaFill)'
            fillOpacity={1}
            activeDot={{ fill: 'var(--color-floraAndFauna)' }}
            dataKey='floraAndFauna'
            connectNulls
          />
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={[0, 1]}>
            <Chart.YAxisLabel>{t('shared.ncaDetail.floraAndFauna.evolution.value')}</Chart.YAxisLabel>
          </Chart.YAxis>

          <Chart.Tooltip
            content={(props) => (
              <Chart.TooltipContent
                {...props}
                labelFormatter={printMonthYear}
                formatter={(v) => getDisplayNumber(v as number)}
              />
            )}
          />

          <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
        </AreaChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const projectAverage = useFact('r1_conservation_indicator_species_score');

  const latestAnalysisDatestamp = useLatestAnalysisDatestamp();

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.floraAndFauna.evolution.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.title'),
            body: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.body'),
          }}
          supplementaryInfo={latestAnalysisDatestamp}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <FactCapsule
            label={t('shared.ncaDetail.floraAndFauna.evolution.projectAverage', {
              year: new Date().getFullYear() - 1,
            })}
            fact={projectAverage}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={<Trans i18nKey='shared.ncaDetail.floraAndFauna.evolution.explanation' />}
      />
    </Stack>
  );
};
