import { useForm, useFormContext } from 'react-hook-form';

import { ScenarioInputs } from './types';

export const useCreateScenarioForm = ({ landPortionId, timeFrame, ...defaultScenario }: ScenarioInputs) => {
  return useForm<ScenarioInputs>({
    defaultValues: {
      landPortionId,
      timeFrame,
      ...defaultScenario,
    },
  });
};

export const useScenarioForm = useFormContext<ScenarioInputs>;
