import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { WaterHistoricalChart } from '@/components/Charts/WaterHistorical';
import { useNCCardContext } from '@/components/NCCard/NCCard';
import { useBenchmarksForPlot } from '@/pages/shared/hooks/useBenchmarkForFact';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

import { NoPlotData } from '../NoPlotData';

export const WaterHistoricalGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  const plot = usePlot().data;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });

  const historicWaterAndSoilMoisture = getFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_historic_water_and_soil_moisture_graph
      : R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
  );
  const whcData = historicWaterAndSoilMoisture?.value;

  const whcLowBenchmark = getFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'low' }] as PlotReportFactLabel[],
  );
  const whcLowBenchmarkData = (whcLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const whcModerateBenchmark = getFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'moderate' }] as PlotReportFactLabel[],
  );
  const whcModerateBenchmarkData = (whcModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const whcHighBenchmark = getFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'high' }] as PlotReportFactLabel[],
  );
  const whcHighBenchmarkData = (whcHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const whcBenchmarks = useBenchmarksForPlot(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
  );

  const noChartData = !whcData?.length;

  if (noChartData) {
    return <NoPlotData />;
  }

  const chartData = [...whcData, ...whcLowBenchmarkData, ...whcModerateBenchmarkData, ...whcHighBenchmarkData];

  return (
    <Stack
      spacing={6}
      className='items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'
      data-testid='water-historical-graph-tile'
    >
      <ChartHeading
        // TODO: MVP-3284 Update copy for historicalGraphHeadline
        heading={t('shared.ncaDetail.details.water.labels.historicalGraphHeadline')}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.historicalData.title'),
          body: t('shared.ncaDetail.details.water.explainers.historicalData.body'),
        }}
      />
      <WaterHistoricalChart
        analysisType={analysisType}
        data={chartData}
        benchmarks={whcBenchmarks}
        showTooltip={true}
        className='relative'
        data-testid='water-historical-chart'
      />
    </Stack>
  );
};
